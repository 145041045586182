import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Divider,  
    Tabs,Tab,
    CardMedia,
    Container,
    Link,
    Button,
    Tooltip

} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles, Theme} from "@material-ui/core/styles";
import ProjectHeaderDetails from "../../../components/src/ProjectHeaderDetails.web";
import PrjMessages from './PrjMessages.web';
import PrjDetails from './PrjDetails.web';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from '@material-ui/icons/Info';
import {
    calender,
    wallet,
    submitwork,
    workicon,
    download,
    rightImage
} from './assets'
import {Timeline,TimelineItem,TimelineSeparator,TimelineConnector,TimelineContent,TimelineDot} from '@material-ui/lab';
import  UploadFilesComp  from "./UploadFilesComp.web";
import FreelancerProjectsLayout from "../../dashboard/src/freelancer-projects/FreelancerProjectsLayout.web";
export const trackingTheme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "12px",
            fontWeight: 500,
            color: "#fff",
            backgroundColor: "#222222",
            padding:'16px',
            border:'none'
          },
          arrow: {
           color: "#222222",
          }
        }
      }
    
});

const StyledTimelineItem = withStyles({
    missingOppositeContent: {
      "&:before": {
        display: "none"
      }
    }
})(TimelineItem);



// Customizable Area End

import PrjSummaryController, {
    Props, configJSON,
    TabPanelProps,
    RevisedDetails
} from "./PrjSummaryController.web";

const a11yProps=(index: number) =>{
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const TabPanel=(props: TabPanelProps)=>{
    return <div
        role="tabpanel"
        data-testid='tabid'
        hidden={props.value !== props.index}
        id={`simple-tabpanel-${props.index}`}
        aria-labelledby={`simple-tab-${props.index}`}
      >
        {props.value === props.index && (
          <Box style={{padding:0}}>
            <Typography>{props.children}</Typography>
          </Box>
        )}
      </div>
   }

export class PrjSummary extends PrjSummaryController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start


  getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean,classes:any) => {
    return (
      <>
        {
          <Typography className={`${classes.timelineContentDesc} ${classes.marginCurrencyStyle}`}>
            <span className={classes.labelStyle}>{configJSON.refundAmtTxt} </span>
            <span>{sponsorCost.currency_symbol ?? ''}{sponsorCost.amount && sponsorCost.amount > 0 && sponsorCost.amount}</span> 
            <span className={classes.freelancerCurrencyColor}>({freelancerCost.currency_symbol}{freelancerCost.amount})</span>
            <Tooltip  enterTouchDelay={0} leaveTouchDelay={3000} title={`${configJSON.convertionText1}(${sponsorCost.currency}) ${configJSON.convertionText2} (${freelancerCost.currency}) ${configJSON.convertionText3}`} arrow placement='top'>
              <InfoIcon className={classes.infoCurrencyIconStyle} />
            </Tooltip>
          </Typography>}
        {hourlyRated && " /hr"}
      </>
    )
  } 

       getTitleAndRoute =() => {
        let title, route;
        if (this.state.isDeletedProject) {
          title = "Closed Project";
          route = "closed";
        }
        else if (this.state.isDisputedProject) {
          title = "Disputed Project";
          route = "dispute";
        }
        else if (this.state.isHoldProject) {
          title = "On-Hold Project";
          route = "onhold";
        }
        else if (this.state.isCompletedProject) {
          title = "Completed Project";
          route = "completed";
        } else {
          title = "Project in Progress";
          route = "progress";
        }
      
        return { title, route };
      }
                                                                                      
      commonDetails=(milestone:{
        total_cost:number,
        due_date:string,
        period_status:string,
        cooling_period_status:string,
        validation_date:string,
        cooling_end_date:string,
        currency_conversion: {
          sponsor: {
              currency: string,
              currency_symbol:  string,
              amount: number
          },
          freelancer: {
              currency:  string,
              currency_symbol:  string,
              amount: number,
          }
       }
         }, )=>{
        const{classes}=this.props;
        return  <>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={"auto"} className={classes.displayVerifyBox} alignContent="center" alignItems="center">
              <img alt ="duedate_img" className={classes.milestoneGridImage} src={calender} />
              <Typography className={`${classes.timelineContentDesc} ${classes.marginStyle}`}>
                <Grid container alignContent="center" alignItems="center">
                  <Grid item xs="auto">
                    <span className={classes.labelStyle}>{configJSON.labelDueDate}</span>
                  </Grid>
                  <Grid item xs="auto">
                    {moment(milestone.due_date).format(configJSON.dueDateFormat)}
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={"auto"} alignContent="center" alignItems="center" className={classes.displayVerifyBox}>
              <img alt ="amt_img"  className={classes.milestoneGridImage} src={wallet} />
              <Typography className={`${classes.timelineContentDesc} ${classes.marginCurrencyStyle}`}>

              <Grid container alignContent="center" alignItems="center">
                <Grid item xs="auto">
                    <span className={classes.labelStyle}>{configJSON.labelTotalAmt}</span>

                </Grid>
                <Grid item xs="auto">
                  <Grid container alignContent="center" alignItems="center">
                    <Grid item xs="auto">
                        {milestone.currency_conversion?.sponsor?.currency_symbol}{milestone.currency_conversion?.sponsor?.amount} 
                    </Grid>
                    <Grid item xs="auto" alignContent="center" alignItems="center">
                {

                    milestone.currency_conversion?.freelancer?.currency !== milestone.currency_conversion?.sponsor?.currency &&
                    (<>
                    <span  data-testid="currencyConversion" className={classes.freelancerCurrencyColor}>({milestone.currency_conversion?.freelancer?.currency_symbol}{milestone.currency_conversion?.freelancer?.amount})</span>
                    <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} data-testid="currencyConversionTooltip" title={`${configJSON.convertionText1}(${milestone.currency_conversion?.sponsor?.currency}) ${configJSON.convertionText2} (${milestone.currency_conversion?.freelancer?.currency}) ${configJSON.convertionText3}`} arrow placement='top'>
                      <InfoIcon className={classes.infoCurrencyIconStyle}/>
                    </Tooltip>
                    </>)
                  }</Grid>
                  </Grid>
                </Grid>
              </Grid>
              </Typography>
                  

            </Grid>
            <Grid item xs={12} sm>
        <Box className={classes.timelineContentVerifyBtn}> {configJSON.labelVerify}</Box>

            </Grid>

       </Grid>
       {(milestone.period_status == configJSON.periodStatus || milestone.cooling_period_status == configJSON.periodStatus ) &&  <Divider className={classes.divider}></Divider>}
       <Box className={classes.validationStyle}>
          
            {milestone.period_status == configJSON.periodStatus &&
                <>
                  <img alt ="duedate_img" className={classes.milestoneGridImage} src={calender} />
                <Typography className={`${classes.timelineContentDesc} ${classes.marginStyle}`}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={"auto"}>
                      <span className={classes.labelStyle}>{configJSON.labelAcceptanceEnd}</span>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                      {moment(milestone.validation_date).format(configJSON.dueDateFormat)}
                    </Grid>
                  </Grid>
                </Typography>
                </>
       }
        {milestone.cooling_period_status == configJSON.periodStatus && <>
                <img alt ="duedate_img" className={classes.milestoneGridImage} src={calender} />
                <Typography className={`${classes.timelineContentDesc} ${classes.marginStyle}`}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={"auto"}>
                      <span className={classes.labelStyle}>{configJSON.labelCoolingEnd}</span>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                      {moment(milestone.cooling_end_date).format(configJSON.dueDateFormat)}
                    </Grid>
                  </Grid>
                </Typography>
                </>
       }
       </Box>
       
        </>
      }

      renderSubmitComp = (milestoneSubmitted:any, milestoneNumberSubmitted:number)=>{
        const{classes}=this.props;
        return  <Timeline className={classes.timelineStyle}>
        <StyledTimelineItem >
            <TimelineSeparator>
              {
                milestoneSubmitted.attributes.is_delayed ?
                <>
                <TimelineDot className={classes.timelineDelayedDotSubmit}>{milestoneNumberSubmitted+1}</TimelineDot>
                <TimelineConnector className={classes.timelineDotConectorSubmit} />
                </>
                :<>
                <TimelineDot className={classes.timelineDotSubmit }> {milestoneNumberSubmitted+1} </TimelineDot>
                <TimelineConnector className={classes.timelineConectorSubmit} />
                
                </>
                }
            </TimelineSeparator>
       
            <TimelineContent>
                <Grid container spacing={1} className={classes.displayBox}>
                  <Grid item xs={"auto"}>
                  {
                    milestoneSubmitted.attributes.is_delayed  ? <Box style={{display:'flex', alignItems:'center'}}>
                    <Typography  className={classes.timelineDelayedContentHeader} >{milestoneSubmitted.attributes.milestone_title} </Typography>
                    <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`${milestoneSubmitted.attributes.milestone_title} ${configJSON.behindTimeTxt}`} arrow placement='top'>
                  <InfoIcon className={classes.infoIconStyle}/>
                  </Tooltip>

                    </Box>
                    :<>
                    <Typography  className={classes.timelineContentHeader} >{milestoneSubmitted.attributes.milestone_title}
                    </Typography>
                    </>
                  }
                    </Grid>
                  <Grid item xs={"auto"}>
                     <Box className={classes.timelineContentSubmitBtn}> 
                     {milestoneSubmitted.attributes.milestone_status===this.state.milestoneStatus[1] && configJSON.fileSubmitLabel }
                     {milestoneSubmitted.attributes.milestone_status===this.state.milestoneStatus[4] && configJSON.completedLabel }
                     </Box>
                     </Grid>
                </Grid>

                <Grid container className={classes.displayGrid}>
                <Typography className={classes.timelineContentDesc}>{milestoneSubmitted.attributes.milestone_description}</Typography>
                
                <Divider className={classes.divider}></Divider>
                 {this.commonDetails(milestoneSubmitted.attributes)}
                <Divider className={classes.divider}></Divider>

                <Box className={classes.displayVerifyBox} mb={2}>
                     <img className={classes.milestoneGridImage} src={submitwork} />
                    <Typography className={classes.timelineContentWorkReview}>{configJSON.labelSubmitWorkReview}</Typography>
                 </Box>
                 <Grid  container className={classes.projectDetailsBox}>
                    {
                     milestoneSubmitted.attributes.documents?.map((doc:any)=>(
                           
                            <Grid item xs="auto" sm={6} key={doc.blob_id}>
                             <Box className={classes.downloadItemBorder}>
                                <Box className={classes.downloadItemBox}>
                                <img className={classes.milestoneGridImage} src={workicon} />
                                <Grid container alignItems="center" alignContent="center">
                                  <Grid item xs="auto">
                                    <Typography noWrap={false} className={classes.downloadfileName} style={{
                                      whiteSpace: 'nowrap',
                                      overflow:'hidden',
                                      textOverflow: 'ellipsis',}}>
                                    {this.onConvertFileName(doc.name)}</Typography>
                                  </Grid>
                                  <Grid item xs="auto">
                                    <Typography className={classes.downloadfileSize}>{this.onConvertFileSize(doc.size)}</Typography>
                                  </Grid>
                                </Grid>
                                </Box>
                                <CardMedia
                                    data-testid="downloadedFile"
                                    component="img"
                                    className={classes.downloadImg}
                                    image={download}
                                    onClick={this.handleDownloadFile.bind(this,doc.url, doc.name)}
                                />
                               </Box>
                            </Grid>
                      ))
                    }
                     </Grid>
                   </Grid>
              </TimelineContent>
        </StyledTimelineItem>

        </Timeline>
      }

      renderPendingComp = (milestone:any, milestoneNumber:number)=>{
        const{classes}=this.props;
        return  <Timeline className={classes.timelineStyle}>
        <StyledTimelineItem >
            <TimelineSeparator>
     
               <TimelineDot className={classes.timelineDotPending}>{this.state.milestonesComplete.length+milestoneNumber+1} </TimelineDot>
            <TimelineConnector className={classes.timelineConectorPending} />
             </TimelineSeparator>

            <TimelineContent>
                <Box className={classes.displayBox}>
                  <Grid container spacing={1}>
                    <Grid item xs="auto">
                      <Typography  className={classes.timelineContentHeader} >{milestone.attributes.milestone_title}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      {milestone.attributes.milestone_status === this.state.milestoneStatus[0] &&<Box className={classes.timelineContentPendingBtn}>
                        { configJSON.pendingLabel }
                      </Box>
                      }
                        {milestone.attributes.milestone_status ===  this.state.milestoneStatus[7] &&<Box className={classes.timelineContentStartedBtn}>
                        { configJSON.startedLabel}
                      </Box>
                      }
                    </Grid>
                  </Grid>
                </Box>

                <Grid container className={classes.displayGrid}>
                <Typography className={classes.timelineContentDesc}>{milestone.attributes.milestone_description}</Typography>
                
                <Divider className={classes.divider}></Divider>
                 {this.commonDetails(milestone.attributes)}
                <Divider className={classes.divider}></Divider>
        
            {/* ----------------------------------Upload files --------------------------------------- */}
            {this.renderUploadFilesComp(milestone, milestoneNumber)}
           
            </Grid>
                
            </TimelineContent>
        </StyledTimelineItem>
       
        </Timeline>

      }

      renderRevisedComp = (milestone:RevisedDetails, milestoneNumber:number)=>{
        const{classes}=this.props;
        const convertedCost = milestone.attributes?.currency_conversion;
        const freelancerConvertedCost = convertedCost?.freelancer;
        const sponsorConvertedCost = convertedCost?.sponsor;
        return  <Timeline className={classes.timelineStyle}>
        <StyledTimelineItem >
            <TimelineSeparator>
        
              {milestone.attributes.milestone_status === this.state.milestoneStatus[3] &&<TimelineDot className={classes.timelineDotRevised}>{milestoneNumber+1} </TimelineDot>}
              {milestone.attributes.milestone_status === this.state.milestoneStatus[5] &&<TimelineDot className={classes.timelineDotCancel}>{configJSON.milestoneCancelSign} </TimelineDot>}
              {milestone.attributes.milestone_status === this.state.milestoneStatus[8] &&<TimelineDot className={classes.timelineDotPending}>{milestoneNumber+1} </TimelineDot>}
              {milestone.attributes.milestone_status === this.state.milestoneStatus[6] &&<TimelineDot className={classes.timelineDotPending}>{milestoneNumber+1} </TimelineDot>}
              {milestone.attributes.milestone_status === this.state.milestoneStatus[9] &&<TimelineDot className={classes.timelineDotPending}>{milestoneNumber+1} </TimelineDot>}

              <TimelineConnector className={classes.timelineConectorPending} />
               
            </TimelineSeparator>

             <TimelineContent>
                <Grid container spacing={1} className={classes.displayBox}>
                  <Grid item xs={"auto"}>
                    <Typography  className={classes.timelineContentHeader} >{milestone.attributes.milestone_title}</Typography>
                  </Grid>
                  <Grid item xs={"auto"}>
                    {milestone.attributes.milestone_status === this.state.milestoneStatus[3]  && <Box className={classes.timelineContentPendingBtn}>

                      {configJSON.reqForRevisionLabel }
                      </Box>
                      }
                      {milestone.attributes.milestone_status === this.state.milestoneStatus[5]  && <Box className={classes.timelineCancelStatus}>
                      {configJSON.milestoneCanceledStatus}
                        </Box>
                      }
                          {milestone.attributes.milestone_status ===  this.state.milestoneStatus[8] &&<Box className={classes.timelineContentOnholdBtn}>
                     { configJSON.milestoneOnHoldStatus}
                     </Box>
                      }
                      {milestone.attributes.milestone_status ===  this.state.milestoneStatus[6] &&<Box className={classes.timelineContentRefundBtn}>
                     { configJSON.milestoneRefundStatus}
                     </Box>
                      }
                     {milestone.attributes.milestone_status ===  this.state.milestoneStatus[9] &&<Box className={classes.timelineContentRefundBtn}>
                     { configJSON.milestoneRefundRejectedStatus}
                     </Box>
                      }
                    </Grid>
                </Grid>

                {(milestone.attributes.milestone_status === this.state.milestoneStatus[6] ||
                milestone.attributes.milestone_status === this.state.milestoneStatus[9])  && 
                <Grid container className={classes.displayGrid}>
                    <Typography className={classes.timelineContentDesc}>{milestone.attributes.milestone_description}</Typography>
                   
                    <Divider className={classes.divider}></Divider>
                    {milestone.attributes.milestone_status === this.state.milestoneStatus[6] && 
                    <>
                      <img alt ="refund_amt"  className={classes.milestoneGridImage} src={wallet} />
                      
                      {this.getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false,classes)}
                      <Divider className={classes.divider}></Divider>
                    </>
                    }

                </Grid>
                }
                
                {milestone.attributes.milestone_status === this.state.milestoneStatus[3] && <Grid container className={classes.displayGrid}>
                <Typography className={classes.timelineContentDesc}>{milestone.attributes.milestone_description}</Typography>
                
                <Divider className={classes.divider}></Divider>
                {this.commonDetails(milestone?.attributes)}
                <Divider className={classes.divider}></Divider>
              {/* ----------------------------------Upload files --------------------------------------- */}
            {this.renderUploadFilesComp(milestone, 0)}
            </Grid>
            } 
            </TimelineContent>
     
        </StyledTimelineItem>
       
        </Timeline>
        
      }

      renderUploadFilesComp = (milestone:any, milestoneNumber:number) => {
      
        return (
          <UploadFilesComp
            data-testid="uploadFilesComp"
            milestoneNumber={milestoneNumber}
            milestone={milestone}
            msgToSponsor={this.state.msgToSponsor}
            onMsgChange={this.handleMsgChange}
            deleteUploadedFiles={this.deleteUploadedFiles}
            uploadedFiles={this.state.uploadedFiles}
            handleFilesDrop={this.handleFilesDrop}
            onConvertFileSize={this.onConvertFileSize}
            onSubmitMilestone={this.submitMilestone}
            documentsError={this.state.documentsError}
            onCancelDetails={this.onCancelDetails}
            btnLoader={this.state.btnLoader}
            onConvertFileName = {this.onConvertFileName}
          />
        );
      };

      renderProjectFinished=(milestone:any,milestoneNumber:number)=>{
       
        const {classes}=this.props;
        return(
          <>
          <Box style={{display:'flex',flexDirection:'row'}}>
       <img className={classes.projectfinishedimage} src={rightImage} />
       <Typography  className={classes.projectFinishedHeader} >Project Completed On {moment(milestone.attributes.project_completed_date).format("DD-MMM-YYYY")}</Typography>
       </Box>
       </>
        )
      }

       renderRevisedComponent = (item: any, index: number) => {
        return (
           (item.attributes.milestone_status === this.state.milestoneStatus[3] || 
            item.attributes.milestone_status === this.state.milestoneStatus[5] ||  
            item.attributes.milestone_status === this.state.milestoneStatus[8] ||
            item.attributes.milestone_status === this.state.milestoneStatus[6] ||
            item.attributes.milestone_status === this.state.milestoneStatus[9]
            ) &&
          this.renderRevisedComp(item, index)
        );
      };
      
       renderSubmitComponent = (item: any, index: number) => {
        return (
          (item.attributes.milestone_status === this.state.milestoneStatus[1] ||
            item.attributes.milestone_status === this.state.milestoneStatus[4]) &&
          this.renderSubmitComp(item, index)
        );
      };

       // Customizable Area End
    render() {
        // Customizable Area Start
          const{classes}=this.props;
          const isStudentFL = ["Student Freelancer", "Student both"].includes(this.state.subRoleName);
          const condition = isStudentFL && this.state.isCompletedProject && this.state.tabvalue === 0;
        // Customizable Area End
        return (

            // Customizable Area Start
          
             <ThemeProvider theme={trackingTheme}>
             <FreelancerProjectsLayout
                navigation={this.props.navigation} 
                title={this.getTitleAndRoute().title}
                route={this.getTitleAndRoute().route}
                data-test-id="FreelancerProjectlayoutId"
                >
                {condition &&
                  (<Grid item xs={12}>
                  <Container className={classes.containerCertificate}>
                  <Button className={classes.viewCertificate_btnbox} style={{textTransform:"none"}}>
                    Certificate is generated for this Project.
                    <span>
                    <Link style={{ color: "#4A4595" }}
                    onClick={this.goToViewCertificate}
                    >View Certificate</Link>
                    </span>
                    
                  </Button>
                </Container>
                </Grid>)
                }
                 <Grid item xs={12} className={classes.headerContentGrid}>
                 {this.state.sponsorLoader ? (
                    <Box className={classes.loaderStyle}>
                      <CircularProgress size={24} style={{ color: 'grey' }} />
                    </Box>
                  ):
                   (<ProjectHeaderDetails 
                    sponsorData={this.state.sponsorData}
                    navigation={this.props.navigation}
                    completed={this.state.isCompletedProject}
                    deleted = {this.state.isDeletedProject}
                    disputed = {this.state.isDisputedProject}
                    onhold = {this.state.isHoldProject}
                    sendMessage={this.send}
                    propsInfo={this.props}
                    />)
                 }
            {/* -------------------------------- Buttons start------------------------------------------------ */}
            <Grid container className={classes.buttonBox}>
                    <Tabs value={this.state.tabvalue} data-test-id='tabsID' onChange={this.handleChange}
                    TabIndicatorProps={{ style: { width:0, height:0 } }}
                    >
                      <Tab className={this.state.tabvalue == 0 ? classes.buttonTabActive : classes.buttonTab } 
                      label={configJSON.btnProjectsSummary} 
                      {...a11yProps(0)}
                      />
                                
                      <Tab 
                      className={this.state.tabvalue == 1 ? classes.buttonTabActive : classes.buttonTab } 
                      label={configJSON.btnProjectsDetails} 
                      {...a11yProps(1)} 
                      />

                    <Box className={classes.hideBox}>              
                    <Tab  
                    className={this.state.tabvalue == 2 ? classes.buttonTabActive : classes.buttonTab } 
                  
                    label={this.state.tabvalue !== 2 ? configJSON.btnMessage
                        :<Box className={classes.tabMsgBoxStyle} >{configJSON.btnMessage}<p className={classes.tabMsgStyle}>2</p></Box>
                    } 
                       {...a11yProps(2)} 
                    />
                    </Box>  
                    </Tabs>
                    {this.state.RefundactionChange && 
                      <button data-test-id="handleChangeStatus"  className={classes.status_button} onClick={(e)=> this.handleChangeStatus(e)}>
                        Check refund status
                      </button>
                    }
                    {this.state.DisputeActionChange && 
                      <button data-test-id="handleChangeStatus"  className={classes.status_button} onClick={(e)=> this.handleChangeStatus(e)}>
                        Check dispute status
                      </button>
                    }
    
            </Grid> 
                            
        {/* -------------------------------- Buttons end------------------------------------------------ */}

        {/* -------------------------------- Milestones start------------------------------------------------ */}

                <Box className={classes.milestoneBox}>
                <TabPanel value={this.state.tabvalue} index={0}>
                  {this.state.milestoneLoader ? (
                    <Box className={classes.loaderStyle}>
                      <CircularProgress size={24} style={{ color: 'grey' }} />
                    </Box>
                  ) : (
                    <>
                      {this.state.milestonesData?.length > 0 ? (
                        <>
                           {this.state.milestonesComplete.map((item: any, index: number) => (
                            <React.Fragment key={item.attributes.id}>
                            {this.renderRevisedComponent(item, index)}
                            {this.renderSubmitComponent(item, index)}
                           
                           {this.state.isCompletedProject &&
                           this.state.milestonesData.length - 1 === index && 
                           this.renderProjectFinished(item,index)
                          } 
                            </React.Fragment>
                          ))}
                         {this.state.milestonesPending.map((item: any, index: number) => (     
                         <React.Fragment key={item.attributes.id}>
                         {this.renderPendingComp(item, index)}
                       </React.Fragment>
                    
                    ))}
                      </>
                      ) 
                      : (
                        <Typography className={classes.errorNoMilestone}>{configJSON.errorNoMilestones}</Typography>
                      )}
                    </>
                  )}
                </TabPanel>


                <TabPanel value={this.state.tabvalue} index={1}>
                    <PrjDetails navigation={this.props.navigation} id={""}/>
                </TabPanel>
                <TabPanel value={this.state.tabvalue} index={2}>
                <PrjMessages navigation={this.props.navigation} id={""} />
                </TabPanel>
               </Box>     
         {/* -------------------------------- MileStones end------------------------------------------------ */}
         </Grid>
               
        </FreelancerProjectsLayout>
                </ThemeProvider>
          
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = (theme: Theme) => ({

headerContentGrid:{ 
    display:'flex',
    flexDirection: 'column' as const, 
    minHeight:'50vh'
}, 
projectfinishedimage:{
  marginLeft:'1.2rem',
  height:'30px',
  width:'30px', 
  display:'flex', 
  alignItems:'center',
  justifyContent:'center',
},
projectDetailsBox:{
    display:'flex', 
    alignItems:'center',
  },

  projectDetailsName:{
    color: '#222222',
    fontSize: '20px',
    fontWeight: 700,
  },

  projectDetailsNameSubBox:{
    display:'flex',
    alignItems:'center',
  },
   buttonBox:{
    display:'flex',
    paddingRight:'2vw',
    margin: '2vw 1vw',
    justifyContent: "space-between",
    gap: "10px",
    alignItems: "center"
  },
  status_button: {
    backgroundColor: "#ec952d",
    color: "#fff",
    borderRadius: "20px",
    border: "none",
    cursor: "pointer",
    height: "30px",
    fontSize: "12px",
    fontWeight: "600" as const
  },
  buttonTab:{
     color: '#8083a3',
    fontSize:'12px',
    fontWeight: 700,
    width: '140px',
    height: '42px',
    backgroundColor: '#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textTransform:'none' as const,
  },
  buttonTabActive:{
    color: '#fff',
    fontSize:'12px',
    fontWeight: 700,
    width: '140px',
    height: '42px',
    borderRadius: '20px',
    backgroundColor: '#ec952d',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textTransform:'none' as const,
  },

  milestoneBox:{ 
    borderRadius: '14px',
    border: '1px solid #e4e6e8',
    margin:'1vw 1vw 5vw 1vw',
    padding:'1vw 0',
},

timelineDotSubmit:{
    backgroundColor:'#ec952d',
    height:'25px',
    width:'25px', 
    display:'flex', 
    alignItems:'center',
    justifyContent:'center',
    boxShadow:'none',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 700,

    marginLeft:'0',
    marginRight:'0',
    },

    timelineDelayedDotSubmit:{
      marginLeft:'0',
      marginRight:'0',
      backgroundColor:'#d90000',
      height:'25px',
      width:'25px', 
      display:'flex', 
      alignItems:'center',
      justifyContent:'center',
      boxShadow:'none',
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 700,
      },
      projectFinishedHeader:{
        color: '#222222',
        fontSize: '16px',
        fontWeight: 700,
        marginLeft:"1rem",
        marginRight:'0.7vw'
      },
    
    timelineContentHeader:{
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    marginRight:'0.7vw'
    },
    timelineDelayedContentHeader:{
      fontWeight: 700,
      marginRight:'0.7vw',
      color: '#d90000',
      fontSize: '16px',
      },
    timelineContentVerifyBtn:{
        color: '#fff',
        fontSize:'10px',
        fontWeight: 700,
        width: '63px',
        height: '24px',
        borderRadius: '12px',
        backgroundColor: ' #16a700',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },

    timelineContentSubmitBtn:{
    color: '#fff',
    fontSize:'10px',
    fontWeight: 700,
    padding: '0px 12px',
    height: '24px',
    borderRadius: '12px',
    backgroundColor: ' #16a700',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    },

    timelineContentDesc:{
    color: '#8083a3',
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
    },
    marginStyle:{
      marginRight:'1vw'
    },
    marginLeftStyle:{
      marginLeft:'1vw'
    },
    labelStyle:{
     marginRight:'6px',
     fontSize:'12px',
     color:'#222'
     },
     validationStyle:{
      width:'100%',
      display:'flex',
      alignItems:'center', 
      justifyContent:'flex-start',
      },
      commonDivider:{
        margin:'1vw 0',
        width:'100%'
      },
    displayBox:{
    display:'flex',
    alignItems:'center',
    margin:'8px 0',

    },
    displayVerifyBox:{
     display:'flex',
    alignItems:'center',
    },

    displayGrid:{
    display:'flex',
    alignItems:'center',
    padding:'2vw',
    borderRadius: '10px',
    backgroundColor: 'rgba(248,248,251,0.7)',
    },
    milestoneGridImage:{
        width: '20px',
        height: '21px',
        marginRight:'1vw',
    },
    downloadImg:{
        width: '26px',
        height: '26px',
        marginRight:'1vw',
        cursor:'pointer'
    },

    timelineConectorSubmit:{
        borderLeft:'1px dashed #ec952d',
        width: '0',
        backgroundColor:'#fff',
    },
    timelineDotConectorSubmit:{
      borderLeft:'1px dashed #d90000',
      width: '0',
      backgroundColor:'#fff',
  },
    divider:{
        margin:'2vw 0',
        width:'100%'
    },
    timelineContentWorkReview:{
        color: '#222222',
        fontSize: '14px',
        fontWeight: 700,
    },

    downloadItemBorder:{
      marginBottom:'1vw',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding:'1vw',
      borderRadius: '10px',
      border: '1px solid #e4e6e8',
      backgroundColor:'#ffffff',
      width:'88%',
      '@media (max-width: 350px)':{
        width: "120px"
      }
    },
    downloadItemBox:{
        display:'flex',
        alignItems:'center',
    },

    downloadfileName:{
        color: '#222222',
        fontSize: '12px',
        fontWeight: 600,
        marginRight:'24px',

        '@media (max-width: 500px)':{
          width: "100px"
        },
        '@media (max-width: 350px)':{
          width: "70px"
        }
    },
    
    downloadfileSize:{
        color: 'rgba(128,131,163,0.76)',
        fontSize: '10px',
        fontWeight: 500,
    },
    timelineDotPending:{
        backgroundColor:'#f2f2f2',
        height:'25px',
        width:'25px', 
        display:'flex', 
        alignItems:'center',
        justifyContent:'center',
        boxShadow:'none',
        color: '#222222',
        fontSize: '14px',
        fontWeight: 700,
        marginLeft:'0',
        marginRight:'0',
        },
        timelineDotAdd:{
            backgroundColor:'#fff',
            height:'30px',
            width:'30px', 
            boxShadow:'none',
         },
    timelineConectorPending:{
        border:'1px dashed #d8d8d8',
         width: '0',
        backgroundColor:'#fff',
        },  
        
        timelineContentPendingBtn:{
            color: '#fff',
            fontSize:'10px',
            fontWeight: 700,
            minWidth: '63px',
            height: '24px',
            borderRadius: '12px',
            backgroundColor: '#f7ad00',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            padding:" 0 12px"
            },  

    timelineStyle:{
        padding: '0px 16px',
    },

 tabMsgBoxStyle:{
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
 },

 tabMsgStyle:{
   backgroundColor:'#fff',
    padding:'4px',
    borderRadius:'50%',
    color:'#ec952d',
    margin:0,
    fontSize: '12px',
    fontWeight: 700,
    width: '20px',
    height:'20px',
 },
 errorNoMilestone:{
  color:'#222',
  padding :"2vw",
  fontSize: '16px',
  fontWeight: 500,
 },
 loaderStyle:{ 
  display: 'flex',
   alignItems: 'center', 
   justifyContent: 'center',
    width: '100%'
   },
   infoIconStyle:{
    height:'18px',
    width:'18px',
    marginRight:'1vw',
    color:'#000'
   },
   hideBox:{
    visibility:'hidden' as const,
   },
   viewCertificateName: {
    fontSize: '18px',
    fontWeight: 600,
    color: "#000000",
    padding: "24px 26px 8px 26px"
   },
   containerCertificate: {
    margin: "-6rem 0 0 0 ",
    [theme.breakpoints.down("sm")]: {
      margin: "-2rem 0 0 0",
    }
   },
    viewCertificate_btnbox:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "36px",
      width: "100%",
      margin: "40px auto",
      backgroundColor: "#FEECD6",
      borderRadius: "18px",
      border: "1px solid #FBC48F",
      color: "#A68E9C",
      position: "relative",
      [theme.breakpoints.down("xs")]:{
        display: "block",
        height: "64px",
      }
    }as const,
    viewCertificate_clearIcon: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "red",
    } as const,

    timelineDotRevised:{
      display:'flex', 
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:'#ec952d',
      height:'25px',
      width:'25px', 
      boxShadow:'none',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,

      marginLeft:'0',
      marginRight:'0',
      },
      timelineDotCancel:{
        backgroundColor:'#d90000',
        height:'25px',
        width:'25px', 
        display:'flex', 
        alignItems:'center',
        justifyContent:'center',
        boxShadow:'none',
        color: '#ffffff',
        fontSize: '22px',
        fontWeight: 400,
        fontFamily:'inter',
        marginLeft:'0',
        marginRight:'0',
    },
    
    timelineCancelStatus:{
      color: '#fff',
      fontSize:'10px',
      fontWeight: 700,
      width: '105px',
      height: '24px',
      borderRadius: '12px',
      backgroundColor: ' #d90000',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      padding:'0 8px'
    },
    cancelBackgroundColor:{
      backgroundColor: ' #d90000',
    },
    refundBackgroundColor:{
      backgroundColor: ' #34c0eb',
    },

    timelineContentStartedBtn:{
      color: '#fff',
      fontSize:'10px',
      fontWeight: 700,
      minWidth: '63px',
      height: '24px',
      borderRadius: '12px',
      backgroundColor: '#3C98DC',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      padding:" 0 12px"
      }, 
      timelineContentOnholdBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color: '#fff',
        fontSize:'10px',
        fontWeight: 700,
        width: '63px',
        height: '24px',
        borderRadius: '12px',
        backgroundColor: '#D9B90B',
        padding:" 0 12px"
        }, 
        timelineDotRefund:{
          backgroundColor:'#34c0eb',
          height:'25px',
          width:'25px', 
          display:'flex', 
          alignItems:'center',
          justifyContent:'center',
          boxShadow:'none',
          color: '#ffffff',
          fontSize: '22px',
          fontWeight: 400,
          fontFamily:'inter',
          marginLeft:'0',
          marginRight:'0',
      },
      timelineContentRefundBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#a6a8b3',
        color: '#fff',
        fontSize:'10px',
        fontWeight: 700,
        height: '24px',
        borderRadius: '12px',
        padding:" 0 12px",
        width: "auto"
        },
        infoCurrencyIconStyle:{
          height:'16px',
          width:'16px',
          color:'#000'
         },
         marginCurrencyStyle:{
          marginRight:'1vw',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          flexWrap: "wrap" as const
        },
        freelancerCurrencyColor:{
          color:"#EC952D" ,
           fontWeight:600,
          }
});

export default withStyles(styles as any)(PrjSummary);
  // Customizable Area End